<template>
  <div v-if="section">
    <h2>Centro de Calificaciones</h2>
    <template v-if="profileMatter">
      <Header
        class="pt-1 pb-4"
        :Matter="profileMatter"
        :section_id="section_id"
        :allows_crud="false"
      ></Header>
    </template>
    <div v-if="!enable_evaluate_view">
      <div>
        <h2>Necesitan Calificación:</h2>
      </div>
      <div class="mx-2 selectors-container">
        <div class="select-div mx-2">
          <div class="div-title">Tipo:</div>
          <div>
            <b-form-select class="selected" v-model="selected_type" size="sm">
              <template #first>
                <b-form-select-option :value="null"
                  >-- Seleccione una de las opciones --</b-form-select-option
                ></template
              >
              <b-form-select-option
                v-for="option in evaluation_types"
                :key="option.id"
                :value="option.id"
              >
                {{ option.value }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <!-- <div class="select-div mx-2">
            <div class="div-title">Categoría:</div>
            <div>
              <b-form-select
                class="selected"
                v-model="selected_category"
                size="sm"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >-- Seleccione una de las opciones --</b-form-select-option
                  ></template
                >
                <b-form-select-option
                  v-for="option in evaluation_categories"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.value }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </div> -->
        <div class="select-div mx-2">
          <div class="div-title">Evaluación:</div>
          <div>
            <b-form-select
              class="selected"
              size="sm"
              v-model="selected_evaluation"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- Seleccione una de las opciones --</b-form-select-option
                ></template
              >
              <b-form-select-option
                v-for="option in evaluationsList"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="select-div mx-2">
          <div class="div-title">Instrumento:</div>
          <div>
            <b-form-select
              class="selected"
              v-model="selected_instrument"
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- Seleccione una de las opciones --</b-form-select-option
                ></template
              >
              <b-form-select-option
                v-for="option in InstrumentList"
                :key="option.id"
                :value="option.id"
              >
                {{ option.title }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="select-div mx-2">
          <div class="div-title">Estudiante:</div>
          <div>
            <b-form-select class="selected" size="sm" v-model="selected_user">
              <template #first>
                <b-form-select-option :value="null"
                  >-- Seleccione una de las opciones --</b-form-select-option
                ></template
              >
              <b-form-select-option
                v-for="option in userPerSection"
                :key="option.id"
                :value="option.id"
              >
                {{ option.first_name }} {{ option.last_name }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="select-div mx-2">
          <div class="div-title">Fecha:</div>
          <div class="datepicker-container">
            <b-form-datepicker
              class="selected"
              v-model="selected_date"
              size="sm"
              placeholder="Seleccione una fecha"
            ></b-form-datepicker>
          </div>
        </div>
        <b-button class="btn-search-grading mr-4" @click="SearchButton"
          ><b-icon icon="search" scale=".7" class="search-icon"></b-icon>
          Buscar
        </b-button>
      </div>
      <GenericBTable
        v-if="list.length > 0"
        :items="filter_list"
        :fields="fields_list"
        :pagination="list.length"
        :show_pagination="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #head(selected)>
          <b-form-checkbox
            v-model="select_all"
            @change="selectAllUsers"
          ></b-form-checkbox>
        </template>
        <template #cell(selected)="row">
          <b-checkbox
            :value="row.index"
            :checked="array_index"
            @change="ChangeSelected(row.item)"
          ></b-checkbox>
        </template>
        <template v-slot:cell(last_login)="row">
          <template v-if="row.item.last_login == null">
            No registrado
          </template>
          <template v-else>
            {{ row.item.last_login | FormatToDateTime }}
          </template>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
            variant="none"
            @click="showEvaluateView(row.item)"
            v-b-tooltip.v-secondary.noninteractive="
              'Evaluación de instrumentos'
            "
            ><b-icon icon="check2-square"></b-icon
          ></b-button>
        </template>
        <template v-slot:cell(update_date)="row">
          {{ row.item.update_date | FormatToDateTime }}
        </template>
        <template v-slot:cell(student_grade)="row">
          {{ row.item.student_grade | Round }}
        </template>
        <template v-slot:cell(student_score)="row">
          {{ row.item.student_score | Round }} / {{ row.item.instrument_score }}
        </template>
      </GenericBTable>
    </div>
    <div v-else>
      <EvaluationView
        :evaluation_id="current_evaluation_id"
        :current_users="users_id"
        :period_id="section.period"
        @backToView="slotBackToView"
      ></EvaluationView>
    </div>
    <DashboardCustomFab
      class="noprint"
      :section_id="section_id"
      :show_needs_grading="false"
    ></DashboardCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NeedsGradingView",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    Header: () => import("@/components/teaching/Planification/Header"),
    EvaluationView: () => import("../Test/Evaluate/EvaluateView.vue"),
    DashboardCustomFab: () =>
      import("@/components/dashboard/DashboardCustomFab"),
  },
  data() {
    return {
      section_id: Number(this.$route.params.section_id),
      selected_type: null,
      // selected_category: null,
      selected_instrument: null,
      selected_evaluation: null,
      selected_user: null,
      selected_date: null,
      select_all: false,
      users_id: [],
      array_index: [],
      filter_list: [],
      list: [],
      evaluation_types: [],
      enable_evaluate_view: false,
      current_evaluation_id: null,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      evaluation_categories: names.EVALUATION_CATEGORIES,
      sections: names.SECTIONS,
      section_evaluations: names.SECTION_EVALUATIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      questionTests: names.QUESTION_TESTS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      matters: names.MATTERS,
      users: names.USERS,
      evaluations: names.EVALUATIONS,
    }),
    section() {
      if (!this.section_id) return null;
      return this.sections.find((x) => x.id == this.section_id);
    },
    profileMatter() {
      if (!this.section) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.section.egress_profile_matter
      );
    },
    userPerSection() {
      if (!this.section) return [];
      return this.users.filter((x) => this.section.students.includes(x.id));
    },
    evaluationsList() {
      if (!this.section) return [];
      return this.evaluations.filter((x) =>
        x.sections.includes(this.section.id)
      );
    },
    questionTestsList() {
      if (!this.evaluationsList) return [];
      if (this.selected_evaluation != null)
        return this.questionTests.filter(
          (x) => x.evaluation == this.selected_evaluation
        );
      let evaluations = this.evaluationsList.map((object) => object.id);
      evaluations = [...new Set(evaluations)];
      return this.questionTests.filter((x) =>
        evaluations.includes(x.evaluation)
      );
    },
    observationInstrumentList() {
      if (!this.evaluationsList) return [];
      if (this.selected_evaluation != null)
        return this.rubrics.filter(
          (x) => x.evaluation == this.selected_evaluation
        );
      let evaluations = this.evaluationsList.map((object) => object.id);
      evaluations = [...new Set(evaluations)];
      return this.rubrics.filter((x) => evaluations.includes(x.evaluation));
    },
    InstrumentList() {
      if (!this.questionTests || !this.observationInstrumentList) return [];
      let list = this.questionTestsList;
      return list.concat(this.observationInstrumentList);
    },
    fields_list() {
      return [
        { key: "selected", label: "", display_column: true },
        {
          key: "first_name",
          label: "Nombre",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "last_name",
          label: "Apellido",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "last_login",
          label: "Último acceso",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "instrument_title",
          label: "Instrumento",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "update_date",
          label: "Última modificación ",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "student_score",
          label: "Puntaje del Estudiante",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "student_grade",
          label: "Nota",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    selectAllUsers() {
      this.array_index = [];
      if (this.select_all) {
        this.filter_list.forEach((x, index) => {
          this.users_id.push(x.student_id);
          this.array_index.push(index);
        });
      }
    },
    ChangeSelected(item) {
      const index = this.users_id.findIndex((x) => x == item.student_id);
      if (index != -1) this.users_id.splice(index, 1);
      else this.users_id.push(item.student_id);
    },
    showEvaluateView(item) {
      if (this.users_id.length > 0) {
        const list = this.filter_list.filter(
          (x) =>
            this.users_id.includes(x.student_id) &&
            item.evaluation_id == x.evaluation_id
        );
        let user_evaluation = list.map((object) => object.student_id);
        this.users_id = [...new Set(user_evaluation)];
        this.enable_evaluate_view = !this.enable_evaluate_view;
        this.current_evaluation_id = item.evaluation_id;
      } else {
        this.$swal({
          title: `Seleccione los usuarios para evaluar.`,
          type: "warning",
          showCancelButton: false,
        });
      }
    },
    fetchEvaluationTypes() {
      this.$restful.Get("/teaching/evaluation-types/").then((response) => {
        this.evaluation_types = response;
      });
    },
    fetchNeedsGrading() {
      this.$restful
        .Get(`/teaching/professor_grading_center/?section=${this.section_id}`)
        .then((response) => {
          setTimeout(() => {
            this.list = response;
          }, 500);
          this.filter_list = response;
          this.select_all = true;
          this.selectAllUsers();
        });
    },
    SearchButton() {
      let list = this.list;
      if (this.selected_type != null) {
        list = list.filter((x) => x.evaluation_type == this.selected_type);
      }
      // if (this.selected_category != null) {
      //   list = list.filter(
      //     (x) => x.evaluation_category == this.selected_category
      //   );
      // }
      if (this.selected_instrument != null) {
        list = list.filter((x) => x.instrument_id == this.selected_instrument);
      }
      if (this.selected_evaluation != null) {
        list = list.filter((x) => x.evaluation_id == this.selected_evaluation);
      }
      if (this.selected_user != null) {
        list = list.filter((x) => x.student_id == this.selected_user);
      }
      if (this.selected_date != null) {
        list = list.filter(
          (x) =>
            this.$moment(x.update_date).format("YYYY-MM-DD") ==
            this.selected_date
        );
      }
      this.filter_list = list;
    },
    slotBackToView() {
      this.fetchNeedsGrading();
      this.enable_evaluate_view = !this.enable_evaluate_view;
    },
  },
  created() {
    this.$hasPermissions();
    this.filter_list = this.list;
    this.fetchEvaluationTypes();
    this.fetchNeedsGrading();
    this.$store.dispatch(names.FETCH_EVALUATION_CATEGORIES);
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        this.$store
          .dispatch(
            names.FETCH_EGRESS_PROFILE_MATTER,
            section.egress_profile_matter
          )
          .then((matter) => {
            this.$store.dispatch(names.FETCH_MATTER, matter.matter);
          });
        section.students.forEach((element) => {
          this.$store.dispatch(names.FETCH_USER, element);
        });
      });
    this.$store
      .dispatch(names.FETCH_EVALUATIONS_BY_SECTION, this.section_id)
      .then((response) => {
        response.forEach((x) => {
          this.$store.dispatch(names.FETCH_QUESTION_TESTS, x.id);
          this.$store.dispatch(names.FETCH_OBSERVATION_INSTRUMENTS, x.id);
          this.$store.dispatch(names.FETCH_SECTION_EVALUATIONS, x.id);
        });
      });
  },
};
</script>
<style scoped>
.selected {
  max-width: 100%;
  align-items: center;
  padding-inline: 2em;
}
.div-title {
  font-weight: bold;
}
.selectors-container {
  width: 100%;
  display: flex;
}
.select-div {
  width: 20%;
}
.datepicker-container .b-form-datepicker {
  padding-inline: 0 !important;
}
.btn-search-grading {
  width: 10%;
  max-height: 2.3em;
  /* margin-bottom: -2%; */
  margin-top: 1.2em;
}
.btn-search-grading:hover {
  color: #fff;
}
.search-icon {
  margin-left: -2%;
}
@media (max-width: 1190px) {
  .search-icon {
    display: none;
  }
  .select-div {
    margin-inline: 0.1em !important;
  }
}
@media (max-width: 991px) {
  .selectors-container {
    display: block;
    width: 98%;
  }
  .search-icon {
    display: inline-block;
  }
  .select-div {
    width: 98%;
    margin-left: 0.5em !important;
    margin-top: 1em;
  }
  .btn-search-grading {
    width: 98%;
    margin-inline: 0.25em !important;
  }
  .custom-select {
    text-align: center;
  }
}
</style>